<template>
  <div class="call-feedback-chat">
    <ChatButton
      :position="buttonPosition"
      :buttonText="staticText.chatButtonText"
      :callId="callId"
    />
  </div>
</template>

<script>
import ChatButton from "@/apps/call_history/CallFeedbackComponents/Chat/ChatButton.vue"

export default {
  name: "CallFeedbackChat",
  components: { ChatButton },
  props: {
    callId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      staticTextDefault: {
        chatButtonText: "AI Insights"
      }
    }
  },
  computed: {
    buttonPosition () {
      return {
        bottom: "10vh", // 10% from bottom
        right: "2vw" // 2% from right
      }
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  }
}
</script>

<style scoped lang="scss">
.call-feedback-chat {
  position: relative;
  z-index: 1050; // Higher than most bootstrap components
}
</style>
