<template>
  <div class="chat-history">
    <div class="history-list">
      <div
        v-for="chat in chats"
        :key="chat.id"
        class="history-item"
        :class="{ active: chat.id === currentChatId }"
        @click="handleChatSelect(chat.id)"
      >
        <div class="bullet"></div>
        <div class="history-text">
          {{ chat.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex"

export default {
  name: "ChatHistory",
  props: {
    chats: {
      type: Array,
      default: () => []
    },
    currentChatId: {
      type: String,
      default: null
    }
  },
  methods: {
    ...mapMutations("chat", ["SET_CURRENT_CHAT"]),
    handleChatSelect (chatId) {
      // Update current chat in store
      this.SET_CURRENT_CHAT(chatId)
      // Emit event for parent to load messages
      this.$emit("select-chat", chatId)
    }
  }
}
</script>

<style scoped lang="scss">
.chat-history {
  background: white;
  height: 100%;
  border-right: 1px solid $gray-200;
  overflow-y: auto;
  width: 224px;

  .history-list {
    padding: 16px 0;
  }

  .history-item {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 8px 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    color: $slate60;

    .bullet {
      flex-shrink: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $slate60;
      margin-top: 8px; // To vertically align with text
    }

    .history-text {
      font-size: 14px;
      line-height: 1.4;
      word-break: break-word;
    }

    &:hover {
      color: $slate80;
      .bullet {
        background-color: $slate80;
      }
    }

    &.active {
      color: $orange;
      background: rgba($orange, 0.06);

      .bullet {
        background-color: $orange;
      }
    }
  }
}
</style>
