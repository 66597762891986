<template>
  <div
    class="chat-button"
    :style="buttonStyle"
  >
    <div
      class="button-content"
      @click="toggleChat"
    >
      <img src="/img/aiAnswerIcons/magic-star-white.svg" svg-inline/>
      <span v-if="!isOpen">{{ buttonText }}</span>
    </div>

    <ChatWindow
      v-if="isOpen"
      :isOpen="isOpen"
      :callId="callId"
      @close="handleClose"
    />
  </div>
</template>

<script>
import ChatWindow from "./ChatWindow.vue"

export default {
  name: "ChatButton",
  components: { ChatWindow },
  props: {
    position: {
      type: Object,
      default: () => ({
        bottom: "20px",
        right: "20px"
      })
    },
    buttonText: {
      type: String,
      default: "AI Insights"
    },
    callId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    buttonStyle () {
      return {
        bottom: this.position.bottom,
        right: this.position.right
      }
    }
  },
  methods: {
    toggleChat () {
      this.isOpen = true
    },
    handleClose () {
      this.isOpen = false
    }
  }
}
</script>

<style scoped lang="scss">
.chat-button {
  position: fixed;
  z-index: 1000;
  transition: all 0.3s ease;

  .button-content {
    cursor: pointer;
    background: $orange;
    color: white;
    padding: 12px 24px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);

    &:hover {
      opacity: 0.9;
    }

    i {
      font-size: 20px;
    }
  }
}
</style>
