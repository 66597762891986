<template>
  <div class="chat-input">
    <form @submit.prevent="sendMessage">
      <div class="input-wrapper">
        <div class="input-group mb-3">
          <textarea
            class="form-control"
            v-model="message"
            :placeholder="staticText.inputPlaceholder"
            :disabled="isDisabled"
            @keydown.enter="handleEnter"
            ref="textarea"
            @input="adjustHeight"
          ></textarea>
        </div>
        <button
          class="btn send-button"
          type="submit"
          :disabled="!message.trim() || isDisabled"
        >
          <img
            :src="sendButtonIcon"
            :alt="staticText.sendButtonLabel"
          />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "ChatInput",
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      message: "",
      staticTextDefault: {
        inputPlaceholder: "Type here...",
        sendButtonLabel: "Send"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    sendButtonIcon () {
      return this.message.trim() && !this.isDisabled
        ? "/img/icons/send-message-orange.svg"
        : "/img/icons/send-message-grey.svg"
    }
  },
  methods: {
    handleEnter (e) {
      if (e.shiftKey) {
        // Allow shift+enter for new line
        return
      }
      e.preventDefault() // Prevent default enter behavior
      this.sendMessage()
    },
    sendMessage () {
      const trimmedMessage = this.message.trim()
      if (trimmedMessage && !this.isDisabled) {
        this.$emit("send", trimmedMessage)
        this.message = ""
        this.$nextTick(() => {
          this.adjustHeight()
        })
      }
    },
    adjustHeight () {
      const textarea = this.$refs.textarea
      if (textarea) {
        textarea.style.height = "auto"
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    }
  },
  mounted () {
    this.adjustHeight()
  }
}
</script>

<style scoped lang="scss">
.chat-input {
  padding: 16px 20px;
  background: white;
  border-top: 1px solid $gray-200;

  .input-wrapper {
    position: relative;
    padding-bottom: 44px;
  }

  .input-group {
    background: white;
    border: 1px solid $gray-200;
    border-radius: 8px;
    overflow: hidden;

    .form-control {
      border: none;
      padding: 12px 16px;
      font-size: 14px;
      color: $slate80;
      min-height: 44px;
      max-height: 120px;
      height: auto;
      overflow-y: auto;
      resize: none;
      text-align: left;
      line-height: 1.5;
      vertical-align: top;

      &::placeholder {
        color: $slate40;
        text-align: left;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .send-button {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 8px;
    background: rgba($slate80,0.1);
    border: none;
    border-radius: 12px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 20px;
    }

    &:hover:not(:disabled) {
      background: rgba($orange, 0.1);
    }

    &:disabled {
      cursor: not-allowed;
      background: rgba($slate80,0.1);
    }
  }
}
</style>
