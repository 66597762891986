<template>
  <div class="chat-header">
    <!-- Title row -->
    <div class="title-row">
      <div class="title-section">
        <img src="/img/aiAnswerIcons/magic-star-orange.svg" class="star-icon" />
        <h5 class="mb-0">{{ staticText.titleText }}</h5>
      </div>
      <button
        class="btn btn-link close-button"
        @click.stop="$emit('close')"
      >
        <img src="/img/icons/close-icon.svg" :alt="staticText.closeButtonLabel" />
      </button>
    </div>

    <!-- History row -->
    <div class="history-row">
      <button
        class="btn btn-link history-button"
        @click.stop="$emit('toggle-history')"
      >
        <img src="/img/icons/menu-bars-with-dots.svg" :alt="staticText.historyIconLabel" />
        <span class="history-text">{{ staticText.historyButtonLabel }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatHeader",
  data: function () {
    return {
      staticTextDefault: {
        titleText: "AI Insights",
        closeButtonLabel: "Close",
        historyButtonLabel: "History",
        historyIconLabel: "Toggle History"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style scoped lang="scss">
.chat-header {
  background: white;
  border-bottom: 1px solid $gray-200;
  border-radius: 12px 12px 0 0;

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;

    .title-section {
      display: flex;
      align-items: center;
      gap: 8px;

      .star-icon {
        width: 20px;
        height: 20px;
      }

      h5 {
        color: $orange;
        font-size: 20px;
        font-weight: 500;
        margin: 0;
      }
    }

    .close-button {
      padding: 4px;
      color: $slate80;

      i {
        font-size: 18px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .history-row {
    padding: 0 20px 12px;

    .history-button {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0;
      color: $slate80;
      text-decoration: none;

      i {
        font-size: 16px;
      }

      .history-text {
        font-size: 14px;
        color: $slate80;
      }

      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
}
</style>
