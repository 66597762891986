<template>
  <div class="chat-placeholders">
    <p class="placeholder-title">{{ staticText.askAnythingLabel }}</p>
    <div class="placeholder-examples">
      <p>{{ staticText.topicsDiscussedExample }}</p>
      <p>{{ staticText.pricingTopicExample }}</p>
      <p>{{ staticText.summaryStructureExample }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatPlaceholders",
  data: function () {
    return {
      staticTextDefault: {
        askAnythingLabel: "Ask anything about this call, e.g.",
        topicsDiscussedExample: "Which topics were discussed?",
        pricingTopicExample: "Was topic \"Pricing\" discussed?",
        summaryStructureExample: "Give me a summary according to the following structure: brief stucture, pain points, next steps"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style scoped lang="scss">
.chat-placeholders {
  padding: 16px 20px;
  color: $slate60;

  .placeholder-title {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .placeholder-examples {
    p {
      font-size: 14px;
      color: $slate60;
      margin-bottom: 8px;
      line-height: 1.4;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
