<template>
  <div
    class="chat-messages"
    ref="messageContainer"
  >
    <template v-if="messages.length > 0">
      <div
        v-for="message in messages"
        :key="message.id"
        class="message"
      >
        <!-- User Message -->
        <div
          v-if="message.content"
          class="message-content user-message"
        >
          <pre>{{ message.content }}</pre>
          <div class="message-time">
            {{ formatTime(message.created_at) }}
          </div>
        </div>

        <!-- AI Response or Loading -->
        <div
          v-if="message.response || (message.id === pendingMessageId && isWaitingForResponse)"
          class="message-content ai-response"
        >
          <div class="response-header">
            <img src="/img/aiAnswerIcons/magic-star-orange.svg" class="star-icon" />
            <bao-copy-button
              v-if="message.response"
              class="copy-button"
              :ref="`copyButton-${message.id}`"
              :shouldInitiateCopyProcess="true"
              @initiate-copy-process="copyResponse(message.response, message.id)"
            />
          </div>

          <!-- Loading Animation -->
          <template v-if="message.id === pendingMessageId && isWaitingForResponse">
            <div class="loading-bubble mt-3" style="min-height: 24px;">
              <div class="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </template>

          <!-- Response Text -->
          <template v-else-if="message.response">
            <div class="response-text mt-3">
              <pre>{{ message.response }}</pre>
            </div>
            <div class="message-time">
              {{ formatTime(message.created_at) }}
            </div>
          </template>
        </div>
      </div>
    </template>

    <ChatPlaceholders v-else-if="!isLoading" />
  </div>
</template>

<script>
import ChatPlaceholders from "./ChatPlaceholders.vue"
import BaoCopyButton from "@/apps/base/BaoCopyButton.vue"
import { mapGetters } from "vuex"

export default {
  name: "ChatMessages",
  components: {
    ChatPlaceholders,
    BaoCopyButton
  },
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    pendingMessageId: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      staticTextDefault: {
        loadingText: "Loading...",
        timeFormat: {
          hour: "2-digit",
          minute: "2-digit"
        }
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    ...mapGetters("chat", ["isWaitingForResponse"])
  },
  methods: {
    formatTime (timestamp) {
      return new Date(timestamp).toLocaleTimeString([], this.staticText.timeFormat)
    },
    scrollToBottom () {
      this.$nextTick(() => {
        const container = this.$refs.messageContainer
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: "smooth"
          })
        }
      })
    },
    copyResponse (response, messageId) {
      const copyButton = this.$refs[`copyButton-${messageId}`]
      if (copyButton && copyButton[0]) {
        copyButton[0].copyText(response)
      }
    }
  },
  watch: {
    "messages.length": function () {
      this.scrollToBottom()
    },
    isLoading: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.scrollToBottom()
        }
      }
    }
  },
  mounted () {
    this.scrollToBottom()
  }
}
</script>

<style scoped lang="scss">
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 16px 0;
  display: flex;
  flex-direction: column;

  .message {
    padding: 0 20px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .message-content {
      max-width: 80%;
      padding: 12px 16px;
      border-radius: 12px;
      position: relative;

      .message-time {
        font-size: 12px;
        color: $slate40;
        margin-top: 4px;
      }

      pre {
        margin: 0;
        font-family: inherit;
        white-space: pre-wrap;
        word-wrap: break-word;
      }

      .response-text {
        color: $slate80;
        line-height: 1.5;
      }
    }

    .user-message {
      align-self: flex-end;
      background: $gray-100;
      color: $slate80;
      border-radius: 12px 12px 0 12px;
    }

    .ai-response {
      align-self: flex-start;
      background: #FFF6F4;
      border: 1px solid $gray-200;
      padding-top: 24px;
      border-radius: 12px 12px 12px 0;

      .response-header {
        position: absolute;
        top: 8px;
        left: 12px;
        right: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .star-icon {
          width: 16px;
          height: 16px;
        }

        .copy-button {
          opacity: 0;
          transition: opacity 0.2s ease;
        }
      }

      .response-text {
        color: $slate80;
        line-height: 1.5;
      }

      &:hover {
        .copy-button {
          opacity: 1;
        }
      }
    }
  }
}

// Loading Animation
.loading-bubble {
  min-width: 60px;

  .typing-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    span {
      width: 8px;
      height: 8px;
      background: $gray-400;
      border-radius: 50%;
      animation: bounce 1.4s infinite ease-in-out;

      &:nth-child(1) { animation-delay: -0.32s; }
      &:nth-child(2) { animation-delay: -0.16s; }
    }
  }
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>
