<template>
  <div
    class="chat-window"
    :class="{
      'open': isOpen,
      'expanded': hasMessages,
      'history-visible': showHistory
    }"
  >
    <ChatHeader
      @close="handleClose"
      @toggle-history="toggleHistory"
    />

    <div class="chat-container">
      <ChatHistory
        v-show="showHistory"
        :chats="sortedChats"
        :currentChatId="currentChatId"
        @select-chat="selectChat"
      />

      <div class="chat-main">
        <div
          class="messages-container"
          ref="messagesContainer"
          @scroll="handleScroll"
        >
          <ChatMessages
            :messages="currentMessages"
            :isLoading="isLoading"
            :pendingMessageId="pendingMessageId"
            ref="chatMessages"
          />
        </div>

        <div class="input-container">
          <ChatInput
            @send="sendMessage"
            :isDisabled="isLoading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import ChatHeader from "./ChatHeader.vue"
import ChatHistory from "./ChatHistory.vue"
import ChatMessages from "./ChatMessages.vue"
import ChatInput from "./ChatInput.vue"

export default {
  name: "ChatWindow",
  components: {
    ChatHeader,
    ChatHistory,
    ChatMessages,
    ChatInput
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    callId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      showHistory: false,
      pendingMessageId: null,
      shouldAutoScroll: true,
      isUserScrolling: false,
      scrollTimeout: null
    }
  },
  computed: {
    ...mapState("chat", ["currentChatId", "isLoading", "currentMessages"]),
    ...mapGetters("chat", ["sortedChats", "currentChat"]),
    hasMessages () {
      return this.currentMessages.length > 0
    }
  },
  methods: {
    ...mapActions("chat", [
      "fetchChats",
      "initiateChat",
      "continueChat",
      "loadChatHistory"
    ]),
    handleClose () {
      this.$emit("close")
    },
    toggleHistory () {
      this.showHistory = !this.showHistory
    },
    async selectChat (chatId) {
      try {
        // Set current chat ID in store
        this.$store.commit("chat/SET_CURRENT_CHAT", chatId)
        // Load chat history
        await this.loadChatHistory(chatId)
        this.$nextTick(() => {
          this.scrollToBottom(true)
        })
      } catch (error) {
        console.error("Failed to load chat history:", error)
      }
    },
    handleScroll () {
      // Clear existing timeout
      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout)
      }

      const container = this.$refs.messagesContainer
      if (!container) return

      // Check if user has scrolled up
      const isAtBottom = this.isScrolledToBottom(container)
      this.shouldAutoScroll = isAtBottom

      // Set a flag that user is actively scrolling
      this.isUserScrolling = true

      // Reset the flag after scrolling stops
      this.scrollTimeout = setTimeout(() => {
        this.isUserScrolling = false
      }, 150)
    },
    isScrolledToBottom (element) {
      const threshold = 50 // pixels from bottom to consider "at bottom"
      return element.scrollHeight - element.scrollTop - element.clientHeight < threshold
    },
    scrollToBottom (force = false) {
      const container = this.$refs.messagesContainer
      if (!container || (!this.shouldAutoScroll && !force)) return

      // Only scroll if we're supposed to auto-scroll and user isn't actively scrolling
      if ((this.shouldAutoScroll || force) && !this.isUserScrolling) {
        container.scrollTop = container.scrollHeight
      }
    },
    async sendMessage (content) {
      try {
        const tempId = Date.now().toString()
        this.pendingMessageId = tempId

        this.$store.commit("chat/SET_WAITING_FOR_RESPONSE", true)

        this.shouldAutoScroll = true
        this.scrollToBottom(true)

        if (!this.currentChatId) {
          // Start new chat with callId
          await this.initiateChat({
            message: content,
            callId: this.callId
          })
        } else {
          // Continue existing chat
          await this.continueChat({
            message: content,
            chatId: this.currentChatId
          })
        }
      } catch (error) {
        console.error("Failed to send message:", error)
      } finally {
        this.pendingMessageId = null
        // Set waiting state back to false after response
        this.$store.commit("chat/SET_WAITING_FOR_RESPONSE", false)
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    }
  },
  async created () {
    if (this.callId) {
      // Only fetch chats list, don't select any chat
      await this.fetchChats({ callId: this.callId })
      // Clear any existing messages and chat selection
      this.$store.commit("chat/SET_CURRENT_MESSAGES", [])
      this.$store.commit("chat/SET_CURRENT_CHAT", null)
    }
  },
  watch: {
    // Only watch for new messages to handle scrolling
    currentMessages: {
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chat-window {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 360px;
  height: 510px;
  background: white;
  border-radius: 12px;
  box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  transform: scale(0);
  opacity: 0;
  transform-origin: bottom right;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &.open {
    transform: scale(1);
    opacity: 1;
  }

  &.expanded {
    height: 660px;
  }

  &.history-visible {
    width: 584px;

    .chat-container {
      display: grid;
      grid-template-columns: 224px 1fr;
      gap: 0;

      .chat-main {
        overflow: hidden;

        .messages-container {
          overflow-y: auto;
        }
      }
    }
  }

  .chat-container {
    display: flex;
    flex: 1;
    overflow: hidden;
    background: white;
    border-radius: 0 0 12px 12px;

    .chat-main {
      flex: 1;
      display: flex;
      flex-direction: column;
      background: white;
      min-width: 0;
      position: relative;

      .messages-container {
        flex: 1;
        overflow-y: auto;
        min-height: 0;
      }

      .input-container {
        position: sticky;
        bottom: 0;
        flex-shrink: 0;
        background: white;
        border-radius: 0 0 12px 0;
        z-index: 1;
      }
    }
  }
}
</style>
